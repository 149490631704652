.5_2 { 
	overflow:hidden;
}.e5_2 { 
	background-color:rgba(251.85499995946884, 250.75000405311584, 255, 1);
	width:859px;
	height:486px;
	position:absolute;
}.e28_9 { 
	background-color:rgba(12.388738375157118, 7.437492907047272, 255, 1);
	width:233.8388671875px;
	height:39.8671875px;
	position:absolute;
	left:322.125px;
	top:168.01171875px;
	border-top-left-radius:20px;
	border-top-right-radius:20px;
	border-bottom-left-radius:20px;
	border-bottom-right-radius:20px;
}.28_9 { 
	border:2px solid rgba(0, 0, 0, 1);
}.e8_0 { 
	color:rgba(63.342707455158234, 164.7777932882309, 208.24999898672104, 1);
	width:194.46804809570312px;
	height:34.440948486328125px;
	position:absolute;
	left:72px;
	top:41px;
	font-family:Poppins;
	text-align:left;
	font-size:27px;
	letter-spacing:0;
}.e8_4 { 
	width:400.2701110839844px;
	height:52.61811065673828px;
	position:absolute;
	left:304.2291564941406px;
	top:16.74212646484375px;
}.e8_5 { 
	width:120px;
	height:50px;
	position:absolute;
	left:30px;
	top:18px;
}.e8_1 { 
	color:rgba(5.719791278243065, 14.359685014933348, 18.06249987334013, 1);
	width:100px;
	height:30px;
	position:absolute;
	left:10px;
	top:10px;
	font-family:Poppins;
	text-align:center;
	font-size:20px;
	letter-spacing:0;
}.e8_6 { 
	width:68px;
	height:50px;
	position:absolute;
	left:190.1350555419922px;
	top:18px;
}.e8_2 { 
	color:rgba(5.719791278243065, 14.359685014933348, 18.06249987334013, 1);
	width:48px;
	height:30px;
	position:absolute;
	left:10px;
	top:10px;
	font-family:Poppins;
	text-align:center;
	font-size:20px;
	letter-spacing:0;
}.e8_7 { 
	width:72px;
	height:50px;
	position:absolute;
	left:298.2701110839844px;
	top:18px;
}.e8_3 { 
	color:rgba(5.719791278243065, 14.359685014933348, 18.06249987334013, 1);
	width:52px;
	height:30px;
	position:absolute;
	left:10px;
	top:10px;
	font-family:Poppins;
	text-align:center;
	font-size:20px;
	letter-spacing:0;
}.e11_10 { 
	background-color:rgba(149.38749432563782, 214.39121425151825, 242.2499817609787, 1);
	width:732.5360107421875px;
	height:187.99017333984375px;
	position:absolute;
	left:72.17986297607422px;
	top:230.56298828125px;
	border-top-left-radius:42px;
	border-top-right-radius:42px;
	border-bottom-left-radius:42px;
	border-bottom-right-radius:42px;
}.e11_12 { 
	color:rgba(5.719791278243065, 14.359685014933348, 18.06249987334013, 1);
	width:662.7423706054688px;
	height:89.4508056640625px;
	position:absolute;
	left:110.95416259765625px;
	top:254.48031616210938px;
	font-family:Poppins;
	text-align:center;
	font-size:30px;
	letter-spacing:0;
}.e25_4 { 
	background-color:rgba(5.719791278243065, 14.359685014933348, 18.06249987334013, 1);
	width:859px;
	height:42.572845458984375px;
	position:absolute;
	left:0px;
	top:445.3405456542969px;
}.e25_5 { 
	color:rgba(39.897213131189346, 24.056767523288727, 222.06250101327896, 1);
	width:111.55069732666016px;
	height:29.17913818359375px;
	position:absolute;
	left:72.17986297607422px;
	top:454.9074401855469px;
	font-family:Poppins;
	text-align:left;
	font-size:20px;
	letter-spacing:0;
}.e28_10 { 
	color:rgba(39.897213131189346, 24.056767523288727, 222.06250101327896, 1);
	width:111.55067443847656px;
	height:29.17913818359375px;
	position:absolute;
	left:205.8020782470703px;
	top:454.9074401855469px;
	font-family:Poppins;
	text-align:left;
	font-size:20px;
	letter-spacing:0;
}.e28_0 { 
	background-color:rgba(63.342707455158234, 164.7777932882309, 208.24999898672104, 1);
	width:663.3389282226562px;
	height:38.267669677734375px;
	position:absolute;
	left:110.3576431274414px;
	top:354.45477294921875px;
}.e27_4 { 
	color:rgba(0, 0, 0, 1);
	width:420.552001953125px;
	height:25.8306884765625px;
	position:absolute;
	left:223.1013946533203px;
	top:362.1082763671875px;
	font-family:Poppins;
	text-align:center;
	font-size:20px;
	letter-spacing:0;
}.e28_8 { 
	color:rgba(0, 0, 0, 1);
	width:145.55276489257812px;
	height:25.830703735351562px;
	position:absolute;
	left:362.09234619140625px;
	top:174.59646606445312px;
	font-family:Poppins;
	text-align:center;
	font-size:20px;
	letter-spacing:0;
}.e37_0 { 
	background-color:rgba(196.00000351667404, 196.00000351667404, 196.00000351667404, 1);
	width:695px;
	height:32px;
	position:absolute;
	left:89px;
	top:112px;
	border-top-left-radius:4px;
	border-top-right-radius:4px;
	border-bottom-left-radius:4px;
	border-bottom-right-radius:4px;
}.37_0 { 
	border:2px solid rgba(0, 0, 0, 1);
}